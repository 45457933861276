import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../config";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const token = new URLSearchParams(location.search).get("token");
  const type = new URLSearchParams(location.search).get("type");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsLoading(true);

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      if (type === "restaurant") {
        await axios.post(`${config.API_BASE_URL}/auth/reset_password`, {
          token,
          new_password: password,
        });
        setIsSuccess(true);
        setTimeout(() => navigate("/login"), 3000);
      } else {
        await axios.post(`${config.API_BASE_URL}/customer/reset_password`, {
          token,
          new_password: password,
        });
        setIsSuccess(true);
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-[#aabeff] to-white font-sans">
      <div className="bg-white/90 rounded-3xl p-10 shadow-lg text-center max-w-[400px] w-[90%]">
        {isLoading ? (
          <div className="flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-opacity-70 mb-5"></div>
            <p className="text-gray-600 text-base leading-relaxed">
              Resetting your password...
            </p>
          </div>
        ) : isSuccess ? (
          <>
            <div className="text-6xl text-green-500 mb-5">✓</div>
            <h2 className="text-2xl font-bold text-gray-700 mb-5">
              Password Reset Successful
            </h2>
            <p className="text-gray-600 text-base leading-relaxed mb-4">
              Your password has been successfully reset.
            </p>
          </>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-gray-700 mb-5">
              Reset Password
            </h2>
            {errorMessage && (
              <div className="text-red-500 mb-4">{errorMessage}</div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-left text-gray-700 mb-2">
                  New Password:
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-left text-gray-700 mb-2">
                  Confirm Password:
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <button
                type="submit"
                className="bg-[#6e8efb] text-white px-6 py-3 text-base rounded-full 
                cursor-pointer transition-all duration-300 ease-in-out 
                hover:bg-[#5c7cfa] hover:-translate-y-0.5 hover:shadow-lg w-full"
              >
                Reset Password
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
