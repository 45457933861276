import React, { useState, useEffect } from "react";
import DailySalesReport from "./DailySalesReport";
import apiClient from "../api/ResturantClient";
import LoadingSpinner from "./LoadingSpinner";

const OrderSummary = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [stats, setStats] = useState({ totalOrders: 0, paidOrders: 0 });
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterOption, setFilterOption] = useState("Pending");
  const restaurant_id = sessionStorage.getItem("restaurant_id");
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [processingOrders, setProcessingOrders] = useState(new Set());
  const [isProcessingBulk, setIsProcessingBulk] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const handleMarkSelectedPaid = async () => {
    if (isProcessingBulk) return;

    setIsProcessingBulk(true);
    try {
      for (const orderId of selectedOrders) {
        await handlePaymentChange(orderId, "Completed");
      }
      setSelectedOrders([]);
    } finally {
      setIsProcessingBulk(false);
    }
  };

  const fetchOrderStats = async () => {
    try {
      const url = `/customer/orders/${restaurant_id}?page=1&per_page=1&filter=all`;
      const response = await apiClient.get(url);
      const { pagination } = response.data;

      // Update stats with total counts regardless of current filter
      setStats({
        totalOrders: pagination.total_orders,
        paidOrders: pagination.paid_orders,
      });
    } catch (error) {
      console.log("Error fetching order stats:", error);
      setStats({ totalOrders: 0, paidOrders: 0 });
    }
  };

  const fetchOrders = async (page = 1, perPage = ordersPerPage) => {
    // setIsLoading(true);
    try {
      if (!restaurant_id) {
        setOrders([]);
        return;
      }
      const url = `/customer/orders/${restaurant_id}?page=${page}&per_page=${perPage}&filter=${filterOption}`;

      const response = await apiClient.get(url);
      const { orders: fetchedOrders, pagination } = response.data;

      // Set filtered orders for table display
      setOrders(fetchedOrders);
      setTotalPages(pagination.total_pages);

      // Fetch total and paid orders counts independently
      await fetchOrderStats();
    } catch (error) {
      console.log("Error details:", error.response);
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(currentPage);
  }, [restaurant_id, currentPage, sortOrder, filterOption, searchQuery]);

  const toggleRowExpand = (orderId) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(orderId)) {
        newSet.delete(orderId);
      } else {
        newSet.add(orderId);
      }
      return newSet;
    });
  };

  const calculateStats = (orders) => {
    setStats({
      totalOrders: orders.length,
      paidOrders: orders.filter((order) => order.status === "Completed").length,
    });
  };

  const handleSortChange = (e) => {
    const newSortOrder = e.target.value;
    setSortOrder(newSortOrder);
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
    setCurrentPage(1);
  };

  const handlePaymentChange = async (orderId, status) => {
    if (processingOrders.has(orderId)) return;

    setProcessingOrders((prev) => new Set(prev).add(orderId));
    try {
      await apiClient.post(
        `/kitchen/restaurant/${restaurant_id}/update-order-status/${orderId}`,
        { status }
      );
      setOrders(
        orders.map((order) =>
          order.order_id === orderId ? { ...order, status } : order
        )
      );
      calculateStats(orders);
    } catch (error) {
      alert(
        `Failed to update order status to ${status}. Please try again later.`
      );
    } finally {
      setProcessingOrders((prev) => {
        const newSet = new Set(prev);
        newSet.delete(orderId);
        return newSet;
      });
    }
  };

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const filteredOrders = orders.filter((order) => {
    const matchesSearch =
      searchQuery === "" ||
      order.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.order_id.toString().includes(searchQuery);

    if (filterOption === "all") {
      return matchesSearch;
    }
    return matchesSearch;
  });

  const totalSelectedAmount = filteredOrders
    .filter((order) => selectedOrders.includes(order.order_id))
    .reduce((total, order) => total + order.total_amount, 0);

  const Pagination = () => {
    return (
      <div className="flex justify-center items-center mt-4 gap-2">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 rounded bg-blue-500 text-white disabled:bg-gray-300"
        >
          Previous
        </button>
        <span className="mx-4">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className="px-3 py-1 rounded bg-blue-500 text-white disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full px-4 md:max-w-6xl mx-auto p-2 md:p-6 bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-center mb-2 md:mb-4">
        Order Summary
      </h2>

      {/* Search and Sales Report side by side */}
      <div className="flex flex-col md:flex-row gap-4 mb-2 md:mb-3">
        <div className="w-full md:w-1/2">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by customer name or order ID..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 pl-8 text-sm md:text-base border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
            <svg
              className="absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <DailySalesReport restaurant_id={restaurant_id} />
        </div>
      </div>

      {/* Filter and Sort Controls */}
      <div className="flex flex-col md:flex-row gap-3 md:gap-4 mb-2 md:mb-3">
        <div className="w-full md:flex-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Filter by:
          </label>
          <select
            className="w-full p-2 text-sm md:text-base border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            value={filterOption}
            onChange={handleFilterChange}
          >
            <option value="Pending">Pending Orders</option>
            <option value="Completed">Completed Orders</option>
            <option value="Cancelled">Cancelled Orders</option>
            <option value="all">All Orders</option>
          </select>
        </div>

        <div className="w-full md:flex-1">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Sort by:
          </label>
          <select
            className="w-full p-2 text-sm md:text-base border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            value={sortOrder}
            onChange={handleSortChange}
          >
            <option value="desc">Newest First</option>
            <option value="asc">Oldest First</option>
          </select>
        </div>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 md:gap-4 mb-3 md:mb-4">
        <div className="bg-gradient-to-br from-blue-50 to-white p-2 md:p-3 rounded-lg shadow-md">
          <h3 className="text-sm md:text-base font-semibold text-gray-700 mb-1">
            Total Orders
          </h3>
          <p className="text-xl md:text-2xl font-bold text-blue-600">
            {stats.totalOrders} {/* Total orders within working hours */}
          </p>
        </div>
        <div className="bg-gradient-to-br from-blue-50 to-white p-2 md:p-3 rounded-lg shadow-md">
          <h3 className="text-sm md:text-base font-semibold text-gray-700 mb-1">
            Paid Orders
          </h3>
          <p className="text-xl md:text-2xl font-bold text-blue-600">
            {stats.paidOrders} {/* Paid orders within working hours */}
          </p>
        </div>
      </div>

      {/* Orders Table */}
      <div className="overflow-x-auto -mx-4 md:mx-0">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                  Select
                </th>
                <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                  Table
                </th>
                <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                  Order Details
                </th>
                <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                  Customer
                </th>
                <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                  Total
                </th>
                <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                  Status
                </th>
                <th className="p-2 md:p-4 text-left text-xs md:text-sm font-semibold text-gray-600">
                  Action
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan="7" className="text-center py-12">
                    <LoadingSpinner />
                  </td>
                </tr>
              </tbody>
            ) : filteredOrders.length === 0 ? (
              <tbody>
                <tr>
                  <td
                    colSpan="7"
                    className="text-center py-12 text-gray-500 text-lg"
                  >
                    {filterOption === "all"
                      ? "No orders till now"
                      : `No ${filterOption} orders found`}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="divide-y divide-gray-200">
                {filteredOrders.map((order) => (
                  <tr key={order.order_id} className="hover:bg-gray-50">
                    <td className="p-2 md:p-4 text-xs md:text-sm">
                      {order.status !== "Completed" &&
                        order.status !== "Pending" &&
                        order.status !== "Approved" &&
                        order.status !== "Cancelled" && (
                          <input
                            type="checkbox"
                            checked={selectedOrders.includes(order.order_id)}
                            onChange={() => handleSelectOrder(order.order_id)}
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          />
                        )}
                    </td>
                    <td className="p-2 md:p-4 text-xs md:text-sm">
                      {order.table_number ? order.table_number : "Pick-up"}
                    </td>
                    <td className="p-2 md:p-4">
                      <div className="flex flex-col">
                        <div className="flex items-center ml-4 gap-2">
                          <span className="font-medium text-center text-gray-900">
                            #{order.order_id}
                          </span>
                          <button
                            className="md:hidden rounded-full p-1 hover:bg-gray-100"
                            onClick={() => toggleRowExpand(order.order_id)}
                          >
                            <svg
                              className={`w-4 h-4 transition-transform ${
                                expandedRows.has(order.order_id)
                                  ? "rotate-180"
                                  : ""
                              }`}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 9l-7 7-7-7"
                              />
                            </svg>
                          </button>
                        </div>
                        <span
                          className={`text-xs text-gray-500 md:block ${
                            expandedRows.has(order.order_id)
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          {new Date(order.order_date).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                          {" • "}
                          {new Date(order.order_date).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "2-digit",
                            }
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="p-2 md:p-4 text-xs md:text-sm">
                      {order.customer_name}
                    </td>
                    <td className="p-2 md:p-4 text-xs md:text-sm">
                      ${order.total_amount.toFixed(2)}
                    </td>
                    <td className="p-2 md:p-4">
                      <span
                        className={`px-2 py-1 rounded-full text-xs font-medium whitespace-nowrap ${
                          order.status === "Completed"
                            ? "bg-green-100 text-green-800"
                            : order.status === "Cancelled"
                            ? "bg-red-100 text-red-800"
                            : order.status === "Ready"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-blue-100 text-blue-800"
                        }`}
                      >
                        {order.status}
                      </span>
                    </td>
                    <td className="p-2 md:p-4">
                      {order.status === "Ready" &&
                        !selectedOrders.includes(order.order_id) && (
                          <button
                            onClick={() =>
                              handlePaymentChange(order.order_id, "Completed")
                            }
                            disabled={processingOrders.has(order.order_id)}
                            className={`px-2 md:px-3 py-1 text-xs md:text-sm ${
                              processingOrders.has(order.order_id)
                                ? "bg-gray-400 cursor-not-allowed"
                                : "bg-green-500 hover:bg-green-600"
                            } text-white rounded-md transition-colors`}
                          >
                            {processingOrders.has(order.order_id)
                              ? "Processing..."
                              : "Mark as Paid"}
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          <Pagination />
        </div>
      </div>

      {/* Bulk Action Bar */}
      {selectedOrders.length > 0 && (
        <div className="sticky bottom-4 left-0 right-0 mx-4 md:mx-6 p-3 md:p-4 bg-white rounded-lg border border-gray-200 shadow-lg flex flex-col md:flex-row justify-between items-start md:items-center gap-3 z-50">
          <p className="text-base md:text-lg font-semibold text-gray-800">
            Total Selected: ${totalSelectedAmount.toFixed(2)}
          </p>
          <button
            onClick={handleMarkSelectedPaid}
            disabled={isProcessingBulk}
            className={`w-full md:w-auto px-4 py-2 text-sm md:text-base ${
              isProcessingBulk
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600"
            } text-white rounded-md transition-colors`}
          >
            {isProcessingBulk ? "Processing..." : "Mark Selected as Paid"}
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
