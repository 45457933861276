import React from "react";

const SetupProgressBar = ({ currentStep }) => {
  const steps = [
    { number: 1, title: "Create Restaurant" },
    { number: 2, title: "Add Menu Items" },
    { number: 3, title: "Complete Setup" },
  ];

  return (
    <div className="w-full max-w-3xl mx-auto mb-8">
      <div className="flex justify-between">
        {steps.map((step) => (
          <div key={step.number} className="flex flex-col items-center">
            <div
              className={`w-10 h-10 rounded-full flex items-center justify-center ${
                currentStep >= step.number
                  ? "bg-[#4e7ab4] text-white"
                  : "bg-gray-200"
              }`}
            >
              {step.number}
            </div>
            <span
              className={`mt-2 text-sm ${
                currentStep >= step.number
                  ? "text-[#4e7ab4] font-semibold"
                  : "text-gray-500"
              }`}
            >
              {step.title}
            </span>
          </div>
        ))}
      </div>
      <div className="relative mt-4">
        <div className="absolute top-0 h-1 bg-gray-200 w-full"></div>
        <div
          className="absolute top-0 h-1 bg-[#4e7ab4] transition-all duration-500"
          style={{ width: `${((currentStep - 1) / 2) * 100}%` }}
        ></div>
      </div>
    </div>
  );
};

export default SetupProgressBar;
