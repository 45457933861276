import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";
import apiClient from "../api/CustomerClient";

const CustomerOrderStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract parameters from location or localStorage
  const restaurant_id =
    location.state?.restaurant_id ||
    new URLSearchParams(location.search).get("restaurant_id") ||
    localStorage.getItem("restaurant_id");
  const orderId =
    location.state?.orderId ||
    new URLSearchParams(location.search).get("orderId") ||
    localStorage.getItem("orderId");
  const token =
    location.state?.token ||
    new URLSearchParams(location.search).get("token") ||
    localStorage.getItem("token");

  // State declarations
  const [orderDetails, setOrderDetails] = useState({});
  const [statuses, setStatuses] = useState([
    { label: "Order Received", value: "Pending", checked: false },
    { label: "Order Confirmed", value: "Approved", checked: false },
    { label: "Being Prepared", value: "Approved", checked: false },
    { label: "Ready", value: "Ready", checked: false },
    { label: "Complete", value: "Completed", checked: false },
  ]);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [detailedOrderInfo, setDetailedOrderInfo] = useState(null);
  const [restaurantDetail, setRestaurantDetail] = useState("");
  const [orderCancelled, setOrderCancelled] = useState(false);

  // Ref to store restaurantToken without triggering re-renders
  const restaurantTokenRef = useRef(token);

  // Fetch restaurant details
  const fetchRestaurantDetails = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/restaurant/get-restaurant/${restaurant_id}`,
        {
          headers: {
            Authorization: `Bearer ${restaurantTokenRef.current}`,
          },
        }
      );

      if (response.data && response.data.data) {
        setRestaurantDetail(response.data.data);
        // Update ref if token changes
        if (response.data.data.token !== restaurantTokenRef.current) {
          restaurantTokenRef.current = response.data.data.token;
        }
      }
    } catch (error) {
      console.log("Error fetching restaurant details:", error);
    }
  };

  // Fetch order status
  const fetchOrderStatus = async () => {
    try {
      const response = await apiClient.get(
        `/customer/order-summary/${restaurant_id}/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${restaurantTokenRef.current}`,
          },
        }
      );
      if (response.status === 200) {
        setOrderDetails(response.data);
        const fetchedStatus = response.data.status;
        if (fetchedStatus === "Cancelled") {
          setOrderCancelled(true);
        } else {
          const statusIndex = statuses.findIndex(
            (status) => status.value === fetchedStatus
          );
          const updatedStatuses = statuses.map((status, index) => ({
            ...status,
            checked: index <= statusIndex,
          }));
          setStatuses(updatedStatuses);
        }
      }
    } catch (error) {
      console.error("Error fetching order status:", error);
    }
  };

  // Fetch detailed order information
  const fetchOrderDetails = async () => {
    try {
      const response = await apiClient.get(
        `/customer/order-summary/${restaurant_id}/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${restaurantTokenRef.current}`,
          },
        }
      );
      setDetailedOrderInfo(response.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  // Toggle detailed order information visibility
  const handleShowOrderDetails = () => {
    if (!detailedOrderInfo) {
      fetchOrderDetails();
    }
    setShowOrderDetails(!showOrderDetails);
  };

  // Navigate back to the menu
  const handleBackToMenu = () => {
    if (restaurantTokenRef.current) {
      navigate(`/menu-display?token=${restaurantTokenRef.current}`, {
        replace: true,
      });
    } else {
      console.error("Token is missing. Unable to navigate to menu display.");
    }
  };

  // useEffect to fetch data on component mount or when restaurant_id changes
  useEffect(() => {
    fetchOrderStatus();
    if (restaurant_id) {
      fetchRestaurantDetails();
    }
  }, [restaurant_id]); // Removed restaurantToken from dependencies

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-200 to-white py-8 px-4">
      <button
        onClick={handleBackToMenu}
        className="text-blue-600 hover:text-blue-800 font-semibold"
      >
        Back to Menu
      </button>

      <div className="max-w-3xl mx-auto p-6 bg-gray-50 rounded-lg shadow-lg mt-20">
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-8">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold text-gray-800">
              {restaurantDetail?.restaurant_name}
            </h1>
            <p className="text-gray-600 mt-2">
              {restaurantDetail?.address}, {restaurantDetail?.city},{" "}
              {restaurantDetail?.state} •{" "}
              {restaurantDetail?.phone?.replace(
                /(\d{3})(\d{3})(\d{4})/,
                "$1-$2-$3"
              )}
            </p>
          </div>

          {orderCancelled ? (
            <div className="text-center py-4">
              <h3 className="text-xl font-semibold text-red-600">
                Your Order is Cancelled
              </h3>
            </div>
          ) : (
            <>
              <div className="space-y-4 mb-6">
                {statuses.map((status, index) => (
                  <label
                    key={index}
                    className={`flex items-center p-3 rounded-lg transition-all ${
                      status.checked ? "bg-blue-50 transform scale-105" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={status.checked}
                      readOnly
                      className="w-5 h-5 text-blue-500 rounded border-gray-300 focus:ring-blue-500"
                    />
                    <span
                      className={`ml-3 ${
                        status.checked
                          ? "font-semibold text-gray-900"
                          : "text-gray-700"
                      }`}
                    >
                      {status.label}
                    </span>
                  </label>
                ))}
              </div>

              <div className="flex justify-between items-center py-4 border-t border-gray-200">
                <span className="text-lg font-semibold text-gray-800">
                  Total
                </span>
                <span className="text-xl font-bold text-blue-600">
                  ${orderDetails.total_amount?.toFixed(2) || "0.00"}
                </span>
              </div>

              <button
                onClick={handleShowOrderDetails}
                className="w-full mt-4 bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-700 transition-colors"
              >
                Order#{detailedOrderInfo?.order_id || orderDetails?.order_id}{" "}
                {showOrderDetails ? "(Hide Details)" : "(Show Details)"}
              </button>

              {showOrderDetails && detailedOrderInfo && (
                <div className="mt-6 bg-gray-50 rounded-lg p-4">
                  <h3 className="text-lg font-semibold text-gray-800 mb-4">
                    Order Details
                  </h3>
                  <ul className="space-y-3">
                    {detailedOrderInfo.items.map((item, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center bg-white p-3 rounded-md shadow-sm"
                      >
                        <span className="font-medium text-gray-800">
                          {item.product_name}
                        </span>
                        <div className="text-gray-600">
                          <span className="mr-4">Qty: {item.quantity}</span>
                          <span>${item.price.toFixed(2)}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <p className="text-right mt-4 font-bold text-lg text-gray-800">
                    Total: ${detailedOrderInfo.total_amount.toFixed(2)}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderStatus;
